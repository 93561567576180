html {
    height: 100%;
}

body {
    min-height: 100%;
}

.App {
    background-color: #282c34;
    padding-bottom: 15px;
    color: white;
    margin: 0;
    min-height: 100vh;
}

.App header {

}


/*
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}
*/
